<template>
  <div 
    class="container" 
    v-loading="loading"
		element-loading-text="数据加载中..."
		element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div class="leftContainer">
      <div class="left-top">
        <div class="title">
          <span>客流量统计</span>
        </div>
        <div class="itemCon">
          <div class="numCon">
            <div class="topItem1">
              <div class="numText">{{(passengerStatistics.yearPassengerFlow/1000).toFixed(2)
}}</div>
            </div>
            <div class="btmText">年客流（千）</div>
          </div>
          <div class="numCon">
            <div class="topItem2">
              <div class="numText">{{(passengerStatistics.monthPassengerFlow/1000).toFixed(2)
}}</div>
            </div>
            <div class="btmText">月客流（千）</div>
          </div>
          <div class="numCon">
            <div class="topItem3">
              <div class="numText">{{(passengerStatistics.quarterPassengerFlow/1000).toFixed(2)
}}</div>
            </div>
            <div class="btmText">季客流（千）</div>
          </div>
        </div>
        <div style="width: 100%;height: 65%;">
          <HolidayData :id="'holiday'" :data="passengerStatistics.scenicMonthsCount" ></HolidayData>
        </div>
      </div>
      <div class="left-bottom">
        <div class="title">
          <span>今日各景区客流量统计</span>
        </div>
        <div style="width: 100%;height: 90%;">
          <ScenicData :data="todayScenicPassengerFlow"></ScenicData>
        </div>
      </div>
    </div>
    <div class="centerContainer">
      <div class="center-top">
        <div class="topLeft">
          <div>今日游客数（人）</div>
          <div>
            <div class="box-item">
              <!-- <li :class="{ 'number-item': !isNaN(item) }" v-for="(item, index) in orderNum" :key="index">
                <span v-if="!isNaN(item)">
                  <i ref="numberItem">0123456789</i>
                </span>
              </li> -->
              <RollNum :number="passengerCount.todayPassengerFlow" :step="0"></RollNum>
            </div>
          </div>
          <div style="font-size: 14px;font-weight: 500;color: #FFFFFF;">截止当前时间段</div>
        </div>
        <div class="topRight">
          <div>累计游客数（人）</div>
          <div>
            <div class="box-item">
              <!-- <li :class="{ 'number-item': !isNaN(item) }" v-for="(item, index) in orderNum2" :key="index">
                <span v-if="!isNaN(item)">
                  <i ref="numberItem2">0123456789</i>
                </span>
              </li> -->
              <RollNum :number="passengerCount.historyPassengerFlow" :step="0"></RollNum>
            </div>
          </div>
          <div style="font-size: 14px;font-weight: 500;color: #FFFFFF;">2022年5月12上线至今</div>
        </div>
      </div>
      <div class="center-mid">
        <div class="title">
          <span>今日客流统计</span>
        </div>
        <div style="width: 100%;height: 80%;">
          <TodayPerson :data="todayPassengerFlowPeriod"></TodayPerson>
        </div>
      </div>
      <div class="center-bottom">
        <div class="title">
          <span>近七天客流统计</span>
        </div>
        <div style="width: 100%;height: 80%;">
          <DynamicData :data="sevenDaysPassengerFlow"></DynamicData>
        </div>
      </div>
    </div>
    <div class="rightContainer">
      <div class="right-top">
        <div class="title">
          <span>景区拥堵情况分析</span>
        </div>
        <div class="flex justify-between" style="padding: 0px 20px;">
          <div class="top-top-item">
            <div class="name">舒适</div>
            <img src="../../assets/image/person07.png" alt="" style="width: 60px;height: 58px;">
            <div>
              <span style="font-size: 24px;font-weight: bold;color: #00E4FF;">{{comfortCount}}</span>
              <span>个</span>
            </div>
          </div>
          <div class="top-top-item">
            <div class="name">正常</div>
            <img src="../../assets/image/person08.png" alt="" style="width: 60px;height: 58px;">
            <div>
              <span style="font-size: 24px;font-weight: bold;color: #00E4FF;">{{normalCount}}</span>
              <span>个</span>
            </div>
          </div>
          <div class="top-top-item">
            <div class="name">拥堵</div>
            <img src="../../assets/image/person09.png" alt="" style="width: 60px;height: 58px;">
            <div>
              <span style="font-size: 24px;font-weight: bold;color: #00E4FF;">{{crowdCount}}</span>
              <span>个</span>
            </div>
          </div>
        </div>
        <div class="tableTitle">
          <div class="titleItem" style="width: 28%;">景区名</div>
          <div class="titleItem" style="width: 28%;">当前客流</div>
          <div class="titleItem" style="width: 22%;">可承载客流</div>
          <div class="titleItem" style="width: 22%;">拥堵情况</div>
        </div>
        <div class="cellCon">
          <div class="cell" v-for="(item, index) in scenicCongestion" :key="index">
            <div class="cellItem" style="width: 28%;">{{ item.name }}</div>
            <div class="cellItem" style="width: 28%;color: #17CAF0;">{{ item.currentPassengerFlow }}</div>
            <div class="cellItem" style="width: 22%;">{{ item.maxPassengerFlow }}</div>
            <div class="cellItem" style="width: 22%;">{{ item.crowdStatus }}</div>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <div class="title">
          <span>历史客流排行榜</span>
        </div>
        <div style="width: 100%;height:90%;">
          <RankData :data="historyScenicPassengerFlow"></RankData>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TodayPerson from "./components/todayPerson.vue";
import ScenicData from "./components/scenicData.vue";
import HolidayData from "./components/holidayData.vue";
import DynamicData from "./components/dynamicData.vue";
import RankData from "./components/rankData.vue";
import RollNum from '../../components/public/rollNum.vue'
export default {
  components: {
    TodayPerson,
    ScenicData,
    HolidayData,
    DynamicData,
    RankData,
    RollNum
  },
  mounted() {
    this.init()
    this.loading = false
    setInterval(()=>{
      this.init()
    },1000*60*2)
  },
  data() {
    return {
      passengerStatistics:{},
      passengerCount:{
        historyPassengerFlow:0,
        todayPassengerFlow:0
      },
      todayScenicPassengerFlow:{},
      todayPassengerFlowPeriod:{},
      sevenDaysPassengerFlow:{},
      scenicCongestion:[
        // {
        //   name:'',
        //   crowdStatus:'',
        //   currentPassengerFlow:0,
        //   maxPassengerFlow:0,
        // }
      ],
      normalCount:0,
      crowdCount:0,
      comfortCount:0,
      historyScenicPassengerFlow:{},
      loading:false,
      
    };
  },
  methods: {
    init(){
      this.getPassengerStatistics()
      this.getPassengerCount()
      this.getTodayScenicPassengerFlow()
      this.getTodayPassengerFlowPeriod()
      this.getSevenDaysPassengerFlow()
      this.getScenicCongestion()
      this.getHistoryScenicPassengerFlow()
    },
    //客流量统计
    async getPassengerStatistics(){
      let r = await this.$api.getPassengerStatistics()
      if(r.code == 0){
        this.passengerStatistics = r.data
      }
    },
    //游客数
    async getPassengerCount(){
      let r = await this.$api.getPassengerCount()
      if(r.code == 0){
        this.passengerCount = r.data
      }
    },
    //今日各景区客流量统计
    async getTodayScenicPassengerFlow(){
      let r = await this.$api.getTodayScenicPassengerFlow()
      if(r.code == 0){
        this.todayScenicPassengerFlow = r.data.scenicTodayCount
      }
    },
    //今日客流统计
    async getTodayPassengerFlowPeriod(){
      let r = await this.$api.getTodayPassengerFlowPeriod()
      if(r.code == 0){
        this.todayPassengerFlowPeriod = r.data.scenicHoursTotalCount
      }
    },
    //近七天客流统计
    async getSevenDaysPassengerFlow(){
      let r = await this.$api.getSevenDaysPassengerFlow()
      if(r.code == 0){
        this.sevenDaysPassengerFlow = r.data.scenicSevenDaysCount
      }
    },
    //景区拥堵情况分析
    async getScenicCongestion(){
      let r = await this.$api.getScenicCongestion()
      if(r.code == 0){
        this.scenicCongestion = r.data
        this.scenicCongestion.forEach((item)=>{
          if(item.crowdStatus=='拥挤'){
            this.crowdCount++
          }else if(item.crowdStatus=='舒适'){
            this.comfortCount++
          }else{
            this.normalCount++
          }
        })
      }
    },
    //历史客流排行榜
    async getHistoryScenicPassengerFlow(){
      let r = await this.$api.getHistoryScenicPassengerFlow()
      if(r.code == 0){
        this.historyScenicPassengerFlow = r.data.scenicTotalCount
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 14px;
  padding: 1% 1.6% 1.6% 1.6%;

  .baseCell {
    width: 100%;
    height: 36px;
    background-image: url("../../assets/image/common05.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    display: flex;
    align-items: center;
  }

  .title {
    width: 100%;
    height: 55px;
    padding: 0px 50px;
    font-size: 20px;
    font-weight: 400;
    color: #17CAF0;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 18px;
      left: 20px;
      width: 14px;
      height: 14px;
      background: #17CAF0;
      opacity: 0.3;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 21px;
      left: 23px;
      width: 8px;
      height: 8px;
      background: #17CAF0;
      border-radius: 50%;
    }
  }

  .conTop {
    width: 100%;
    height: 54%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
  }

  .conBottom {
    width: 100%;
    height: 45%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
  }

  .leftContainer {
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .left-top {
      @extend .conTop;
      background-image: url("../../assets/image/common01.png");

      .itemCon {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;

        .numCon {
          width: 33%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .topItem {
            width: 98px;
            height: 58px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            margin: 5px 30px;

            .btmText {
              width: 71px;
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              font-weight: bold;
              color: #FFFFFF;
              margin-top: 40px;
            }
          }

          .topItem1 {
            @extend .topItem;
            background-image: url("../../assets/image/person01.png");

            .numText {
              width: 71px;
              font-size: 26px;
              font-weight: bold;
              background: linear-gradient(0deg, #2AE58B 0%, #0AB391 100%);
              color: #FFFFFF;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              margin-top: -10px
            }
          }

          .topItem2 {
            @extend .topItem;
            background-image: url("../../assets/image/person02.png");

            .numText {
              width: 71px;
              font-size: 26px;
              font-weight: bold;
              background: linear-gradient(0deg, #27E0F6 0%, #0B8FB2 100%);
              color: #FFFFFF;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              margin-top: -10px
            }
          }

          .topItem3 {
            @extend .topItem;
            background-image: url("../../assets/image/person03.png");

            .numText {
              width: 71px;
              font-size: 26px;
              font-weight: bold;
              background: linear-gradient(0deg, #3E8BFF 0%, #3365B8 100%);
              color: #FFFFFF;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              margin-top: -10px
            }
          }
        }
      }
    }

    .left-bottom {
      @extend .conBottom;
      background-image: url("../../assets/image/common02.png");
    }
  }

  .centerContainer {
    width: 47%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .center-top {
      @extend .conTop;
      height: 23%;
      background-image: url("../../assets/image/person04.png");
      display: flex;
      justify-content: space-between;

      .topLeft {
        width: 42%;
        height: 100%;
        padding-top: 7%;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 400;
        color: #00D8FF;

        /*订单总量滚动数字设置*/
        .box-item {
          position: relative;
          height: 52px;
          font-size: 36px;
          font-weight: bold;
          color: #00D8FF;
          line-height: 36px;
          text-align: center;
          list-style: none;
          writing-mode: vertical-lr;
          text-orientation: upright;
          /*文字禁止编辑*/
          -moz-user-select: none;
          /*火狐*/
          -webkit-user-select: none;
          /*webkit浏览器*/
          -ms-user-select: none;
          /*IE10*/
          -khtml-user-select: none;
          /*早期浏览器*/
          user-select: none;
          /* overflow: hidden; */
        }

        /*滚动数字设置*/
        .number-item {
          width: 47px;
          height: 52px;
          background: #ccc;
          list-style: none;
          margin-right: 5px;
          background: url("../../assets/image/person06.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 0px 0px;

          &>span {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 100%;
            height: 100%;
            writing-mode: vertical-rl;
            text-orientation: upright;
            overflow: hidden;

            &>i {
              font-style: normal;
              position: absolute;
              top: 8px;
              left: 50%;
              transform: translate(-50%, 0);
              transition: transform 1s ease-in-out;
              letter-spacing: 10px;
            }
          }
        }

        .number-item:last-child {
          margin-right: 0;
        }
      }

      .topRight {
        width: 42%;
        height: 100%;
        padding-top: 7%;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 400;
        color: #00D8FF;

        /*订单总量滚动数字设置*/
        .box-item {
          position: relative;
          height: 52px;
          font-size: 36px;
          font-weight: bold;
          color: #00D8FF;
          line-height: 36px;
          text-align: center;
          list-style: none;
          writing-mode: vertical-lr;
          text-orientation: upright;
          /*文字禁止编辑*/
          -moz-user-select: none;
          /*火狐*/
          -webkit-user-select: none;
          /*webkit浏览器*/
          -ms-user-select: none;
          /*IE10*/
          -khtml-user-select: none;
          /*早期浏览器*/
          user-select: none;
          /* overflow: hidden; */
        }

        /*滚动数字设置*/
        .number-item {
          width: 47px;
          height: 52px;
          background: #ccc;
          list-style: none;
          margin-right: 5px;
          background: url("../../assets/image/person06.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 0px 0px;

          &>span {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 100%;
            height: 100%;
            writing-mode: vertical-rl;
            text-orientation: upright;
            overflow: hidden;

            &>i {
              font-style: normal;
              position: absolute;
              top: 8px;
              left: 50%;
              transform: translate(-50%, 0);
              transition: transform 1s ease-in-out;
              letter-spacing: 10px;
            }
          }
        }

        .number-item:last-child {
          margin-right: 0;
        }
      }
    }

    .center-mid {
      @extend .conTop;
      height: 36%;
      background-image: url("../../assets/image/person05.png");
    }

    .center-bottom {
      @extend .conBottom;
      background-image: url("../../assets/image/person05.png");
      height: 36%;
    }
  }

  .rightContainer {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .right-top {
      @extend .conTop;
      background-image: url("../../assets/image/common01.png");
      display: flex;
      flex-direction: column;

      .tableTitle {
        width: 100%;
        height: 11%;
        display: flex;
        align-items: center;
        padding: 0px 3%;
        margin-top: 2%;
        color: #17CAF0;


        .titleItem {
          display: flex;
          justify-content: center
        }
      }

      .cellCon {
        width: 100%;
        height: 45%;
        padding: 0px 3%;
        // display: flex;
        // flex-direction: column;
        overflow: auto;

        .cell {
          @extend .baseCell;
          font-size: 14px;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 16px;

          .cellItem {
            display: flex;
            justify-content: center;
          }
        }
      }


      .top-top-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 128px;
        height: 132px;
        background: #133256;
        justify-content: space-between;
        padding: 26px 0px 12px 0px;

        .name {
          position: absolute;
          top: 8px;
          left: 9px;
          font-size: 15px;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .right-bottom {
      @extend .conBottom;
      background-image: url("../../assets/image/common02.png");
    }
  }
}
</style>
<style lang="scss">
</style>