<template>
    <div id="rankColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
        data:Object
    },
    components: {},
    mounted() {
        this.childData = this.data
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
            childData:null
        };
    },
    watch:{
        data(val){
            this.childData = val
            this.draw()
        }
    },
    methods: {
        draw() {
            var chartDom = document.getElementById("rankColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            let yData=[]
            let seriesData=[]
            let maxY = 0
            let maxYArr = []
            console.log(this.childData)
            for (const key in this.childData) {
                yData.push(key)
                seriesData.push(this.childData[key])
                maxY = maxY + this.childData[key]
            }
            for(let i=0;i<yData.length;i++){
                maxYArr.push(maxY)
            }
            console.log(yData)
            console.log(seriesData)
            var option = {
                grid: {
                    left: "5%",
                    right: "5%",
                    bottom: "5%",
                    top: "10%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                    formatter: function (params) {
                        return (
                            params[0].name +
                            "<br/>" +
                            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                            params[0].seriesName +
                            " : " +
                            Number(
                                params[0].value
                            ).toLocaleString() +
                            "人<br/>"
                        );
                    },
                },
                backgroundColor: "",
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: yData,
                    },
                    {
                        type: "category",
                        inverse: true,
                        axisTick: "none",
                        axisLine: "none",
                        show: true,
                        axisLabel: {
                            textStyle: {
                                color: "#ffffff",
                                fontSize: "12",
                            },
                            formatter: function (value) {
                                if (value >= 10000) {
                                    return (value / 10000).toLocaleString() + "万";
                                } else {
                                    return value.toLocaleString();
                                }
                            },
                        },
                        data: seriesData,
                    },
                ],
                series: [
                    {
                        name: "客流量",
                        type: "bar",
                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 30,
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                    {
                                        offset: 0,
                                        color: "rgb(46,200,207,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgb(57,89,255,1)",
                                    },
                                ]),
                            },
                        },
                        barWidth: 12,
                        data: seriesData,
                    },
                    {
                        name: "背景",
                        type: "bar",
                        barWidth: 12,
                        barGap: "-100%",
                        data: maxYArr,
                        itemStyle: {
                            normal: {
                                color: "#0b4874",
                                barBorderRadius: 30,
                            },
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>