<template>
    <div id="holidayColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
        data:Object,
    },
    components: {},
    mounted() {
        this.childData = this.data
        this.draw();
    },
    watch:{
        data(val){
            this.childData = val
            this.draw()
        }
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
            childData:null,
        };
    },
    methods: {
        draw() {
            var chartDom = document.getElementById("holidayColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            let currentData = []
            for(let key in this.childData){
                currentData.push(this.childData[key])
            }
            var data = {
                xData: ["一月", "二月", "三月", "四月", "五月", "六月", "七月","八月","九月","十月","十一月","十二月"],
                
                maxData: [
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    },
                    {
                        value: 2000,
                    }
                ],
            };

            var option = {
                backgroundColor: "",
                grid: {
                    //图表的位置
                    top: "15%",
                    left: "12%",
                    right: "8%",
                    bottom: "12%",
                    // containLabel: true
                },
                xAxis: {
                    show: true,
                    type: "category",
                    // boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "#57617B",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 10,
                        textStyle: {
                            color: "#07DBFF",
                            fontSize: "12",
                        },
                    },
                    data: data.xData,
                },
                yAxis: {
                    show: true,
                    type: "value",
                    name: "客流量/人",
                    nameTextStyle: {
                        color: '#07DBFF',
                        fontSize: '12px',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#57617B",
                        },
                    },
                    axisLabel: {
                        margin: 10,
                        textStyle: {
                            color: "#07DBFF",
                            fontSize: "12",
                        },
                    },
                    axisPointer: {
                        show: true,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(255,255,255,0.1)",
                        },
                    },
                },
                animationEasing: "elasticOut",
                series: [
                    {
                        name: "正常",
                        type: "pictorialBar",
                        symbol: "rect",
                        symbolRepeat: true,
                        symbolSize: [16, 8],
                        symbolMargin: 1,
                        barMinHeight:2,
                        itemStyle: {
                            normal: {
                                color: {
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "#F0E203", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#FF9600", // 100% 处的颜色
                                        },
                                    ],
                                },
                            },
                        },
                        label: {
                            show: true,
                            position: "top",
                            color: "#fff",
                            // formatter: '{c}%',
                            formatter: "{c}",
                        },
                        data: currentData,
                    },
                    // {
                    //     name: "总量",
                    //     type: "pictorialBar",
                    //     symbol: "rect",
                    //     symbolRepeat: false,
                    //     symbolSize: [60, 8],
                    //     symbolMargin: 1,
                    //     color: "#073c5c",
                    //     barGap: "-100%",
                    //     label: {
                    //         show: false,
                    //         position: "top",
                    //         color: "#fff",
                    //         formatter: "{c}",
                    //     },
                    //     z: 1,
                    //     data: data.maxData,
                    // },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>