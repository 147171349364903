<template>
  <div id="main6" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
  props: {
    id: String,
    data:Object
  },
  components: {},
  mounted() {
    this.draw();
  },
  data() {
    return {
      random: Math.round(Math.random() * 80 + 20),
      childData:null,
    };
  },
  watch:{
    data(val){
        this.childData = val
        this.draw()
    }
  },
  methods: {
    draw() {
      var app = {};
      var chartDom = document.getElementById("main6");
      var myChart = this.$echarts.init(chartDom, "dark");
      var option;
      setTimeout(() => {
        window.onresize = function () {
          // 自适应大小
          myChart.resize();
        };
      }, 100);
      let xData=[]
      let seriesData=[]
      for (const key in this.childData) {
        xData.push(key.split(' ')[0].split('-').splice(1,2).join('-'))
        seriesData.push(this.childData[key])
      }
      
      option = {
        backgroundColor: "",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        toolbox: {
          show: false,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        grid: {
          top: "10%",
          left: "6%",
          right: "4%",
          bottom: "16%",
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
            axisTick: {
              alignWithLabel: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              }
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "",
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
          },
        ],
        series:
        {
          name: "客流量/人",
          nameTextStyle: {
            color: '#07DBFF',
            fontSize: '12px',
          },
          type: "line",
          // smooth: true,
          symbol: "circle",
          symbolSize: 5,
          sampling: "average",
          itemStyle: {
            color: "#14E5D3",
          },
          stack: "a",
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00FFFF",
              },
              {
                offset: 1,
                color: "rgba(0, 193, 241, 0.2)",
              },
            ]),
          },
          data: seriesData,
        }
      };
      app.count = 10;
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>