<template>
    <div id="personMColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
        data:Object
    },
    components: {},
    mounted() {
        this.childData = this.data
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
            childData:null,
        };
    },
    watch:{
        data(val){
            this.childData = val
            this.draw()
        }
    },
    methods: {
        draw() {
            var chartDom = document.getElementById("personMColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            let xData=[]
            let seriesData=[]
            for (const key in this.childData) {
                xData.push(key.split(' ')[1])
                seriesData.push(this.childData[key])
            }
            const option = {
                backgroundColor: "",
                title: {
                    text: "",
                    x: "center",
                    y: "4%",
                    textStyle: {
                        color: "#fff",
                        fontSize: "22",
                    },
                    subtextStyle: {
                        color: "#90979c",
                        fontSize: "16",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    // trigger: "item",
                    formatter: function (parms) {
                        
                        var str =
                            parms[0].name +
                            '客流量' +
                            "</br>" +
                            parms[0].marker +
                            "" +
                            parms[0].value +
                            '人'
                        parms.name
                        return str;
                    },
                },
                grid: {
                    top: "15%",
                    right: "4%",
                    left: "8%",
                    bottom: "16%",
                },
                xAxis: [
                    {
                        type: "category",
                        data: xData,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        axisLabel: {
                            margin: 10,
                            color: "#07DBFF",
                            textStyle: {
                                fontSize: 14,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "客流量/人",
                        nameTextStyle: {
                            color: "#07DBFF",
                        },
                        axisLabel: {
                            formatter: "{value}",
                            color: "#07DBFF",
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        data: seriesData,
                        barWidth: "14px",
                        barMinHeight:2,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#3AB7F7", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#49FCED", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                barBorderRadius: [30, 30, 30, 30],
                                shadowColor: "rgba(0,160,221,1)",
                                shadowBlur: 4,
                            },
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>