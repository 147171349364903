<template>
    <div id="scenicColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
        data:Object
    },
    components: {},
    mounted() {
        this.childData = this.data
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
            childData:null,
        };
    },
    watch:{
        data(val){
            this.childData = val
            this.draw()
        }
    },
    methods: {
        draw() {
            var chartDom = document.getElementById("scenicColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            var a = 12; //X fontSize
            // var b = 15; //轴数据 fontSize
            var xAxisTextColor = "#07DBFF"; //'#4e7199';//字体颜色
            // var legendColor = "#FFFFFF"; //legend颜色
            let xData=[]
            let seriesData=[]
            let seriesData1 = []
            for (const key in this.childData) {
                xData.push(key)
                seriesData.push(this.childData[key])
                seriesData1.push(1)
            }
            var option = {
                backgroundColor: "",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (parms) {
                        console.log(678, parms);
                        var str =
                            parms[0].name +
                            '客流量' +
                            "</br>" +
                            parms[0].marker +
                            "" +
                            parms[0].value +
                            '人'
                        parms.name
                        return str;
                    },
                },
                grid: {
                    //top:'30%',
                    //left: '20%',
                },
                yAxis: [
                    {
                        name: "客流量/人",
                        nameTextStyle: {
                            color: xAxisTextColor,
                            fontSize: a,
                        },
                        type: "value",
                        axisLabel: {
                            interval: 0,
                            color: "#07DBFF",
                            textStyle: {
                                fontSize: a,
                            },
                        },
                        splitLine: {
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            }
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                ],
                xAxis: [
                    {
                        type: "category",
                        data: xData,
                        offset:15,
                        axisLabel: {
                            interval: 0,
                            color: "#07DBFF",
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            }
                        },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: "线损率",
                        type: "pictorialBar",
                        symbol: "path://M35,0L35,70L0,70z M35,0L35,70L70,70z",
                        data: seriesData,
                        barWidth: 30,
                        // barGap: 1, //柱子之间间距
                        symbolOffset: [0, 0],
                        z: 99,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                    {
                                        offset: 0,
                                        color: "#00DFE2",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#0ECCFB",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#0AFCFF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#0ECCFB",
                                    },
                                ]),
                                opacity: 1,
                            },
                        },
                    },
                    {
                        type: "pictorialBar",
                        symbol: "diamond",
                        barWidth: 30,
                        symbolOffset: [0, 15],
                        symbolSize: ["100%", 30],
                        z: 99,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                    {
                                        offset: 0,
                                        color: "#00DFE2",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#0ECCFB",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#0AFCFF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#0ECCFB",
                                    },
                                ]),
                                opacity: 1,
                            },
                        },
                        data: seriesData1,
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>